import playLogoApp from "../assets/play-logo-app.png";

function AppCard({
  name,
  img,
  link,
}: {
  name: string;
  img: string;
  link: string;
}) {
  return (
    <a
      className="flex flex-col gap-1 justify-center items-center"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <img src={img} alt={name} />
      <p className="text-[10px] md:text-[12px] text-[#606060] text-center">{name}</p>
      <div>
        <img src={playLogoApp} alt="play logo link" className="object-contain w-24"/>
      </div>
    </a>
  );
}

export default AppCard;
