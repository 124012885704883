import PageWrapper from "../components/PageWrapper";
// import tacticalIcon from "../assets/tactical_nav_icon.png";
// import playLogoGreen from "../assets/playLogoGreen.png";
// import banner from "../assets/banner.png";
import Footer from "../components/Footer";
import AppCard from "../components/AppCard";
import apps from "../constants";

function Projects() {
  return (
    <PageWrapper>
      <div className="flex flex-col justify-between gap-4 md:gap-8 w-[100%] h-[100%] pr-10 pl-10 md:pr-36 md:pl-36">
        {/* <div className="hidden md:flex md:flex-col md:gap-10 lg:flex lg:flex-row lg:gap-10">
          <div className="flex items-start gap-4">
            <img
              src={tacticalIcon}
              alt="tactical navigation app icon"
              className="w-20 h-20 object-contain"
            />
            <a
              className="flex flex-col gap-4 mt-2"
              href="https://play.google.com/store/apps/details?id=com.ekik.tacticalnavigation"
              target="_blank"
              rel="noreferrer"
            >
              <p className="text-regular md:text-xl">
                TACTIVAL NAV. <br /> MILITARY BUNDLE
              </p>
              <p className="text-[12px] md:text-[14px] text-[#606060]">
                TACTICAL NAVIGATION APPS <br /> ARMY SURVIVAL HANDBOOKS <br />{" "}
                GLOBAL CONFLITS LIVE NEWS
              </p>
              <img
                src={playLogoGreen}
                alt="play logo green"
                className="w-24 h-24 object-contain"
              />
            </a>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.ekik.tacticalnavigation"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={banner}
              alt="tactical navigation app icon"
              className="object-contain"
            />
          </a>
        </div> */}
        <div>
          <hr className="mb-8" />
          <h2 className="font-bold text-[14px] md:text-lg mb-8">
            EKIK: Geospatial Research Team Projects
          </h2>
        </div>
        <div className="grid grid-rows-1 grid-cols-1 md:grid-rows-4 md:grid-cols-4 lg:grid-rows-2 lg:grid-cols-8 gap-10">
          {apps.map((app, index) => {
            return(
              <AppCard img={app.img} name={app.name} link={app.link} key={index}/>
            )
          })}
        </div>
      </div>
      <Footer />
    </PageWrapper>
  );
}

export default Projects;
