import Footer from "../components/Footer";
import PageWrapper from "../components/PageWrapper";

function Privacy() {
  return (
    <PageWrapper>
      <div className="pr-10 pl-10 md:pr-24 md:pl-24">
        <h2 className="text-xl font-semibold">Privacy Policy</h2>
        <h3 className="mt-10 mb-5 font-semibold text-regular">
          EKIK Privacy Policy & Terms and Conditions
        </h3>
        <p className="mb-5 text-sm">
          This privacy policy sets out how EKIK uses and protects any
          information that you give EKIK when you use this website or
          application (app).
        </p>
        <p className="mb-5 text-sm">
          EKIK is committed to ensuring that your privacy is protected. Should
          we ask you to provide certain information by which you can be
          identified when using this website or app, then you can be assured
          that it will only be used in accordance with this privacy statement.
        </p>
        <p className="mb-5 text-sm">
          EKIK may change this policy from time to time by updating this page.
          You should check this page from time to time to ensure that you are
          happy with any changes. This policy is effective from 1st January,
          2021.
        </p>
        <h3 className="mt-10 mb-5 font-semibold text-regular">What we collect</h3>
        <p className="mb-5 text-sm">We do not collect any information from the user</p>
        <h3 className="mt-10 mb-5 font-semibold text-regular">Third-party websites</h3>
        <p className="mb-5 text-sm">
          There are a number of places on our Service where you may click on a
          link to access other websites that do not operate under this Privacy
          Policy. For example, if you click on an advertisement on our Service,
          you may be taken to a website that we do not control. These third-
          party websites may independently solicit and collect information,
          including personal information, from you and, in some instances,
          provide us with information about your activities on those websites.
          We recommend that you consult the privacy statements of all
          third-party websites you visit by clicking on the “privacy” link
          typically located at the bottom of the webpage you are visiting.
        </p>
        <h3 className="mt-10 mb-5 font-semibold text-regular">Exclusions and Limitations</h3>
        <p className="mb-5 text-sm">
          The information on this web site is provided on an “as is” basis. To
          the fullest extent permitted by law, this Company:
        </p>
        <ul className="mb-5 pl-4 text-sm">
          <li className="list-disc">
            <p className="text-sm">
              Excludes all representations and warranties relating to this
              website or app and its contents or which is or may be provided by
              any affiliates or any other third party, including in relation to
              any inaccuracies or omissions in this website or app and/or the
              Company’s literature; and
            </p>
          </li>
          <br />
          <li className="list-disc">
            Excludes all liability for damages arising out of or in connection
            with your use of this website or app. This includes, without
            limitation, direct loss, loss of business or profits (whether or not
            the loss of such profits was foreseeable, arose in the normal course
            of things or you have advised this Company of the possibility of
            such potential loss), damage caused to your computer, computer
            software, systems and programs and the data thereon or any other
            direct or indirect, consequential and incidental damages.
          </li>
        </ul>
        <p className="mb-5 text-sm">
          We will not sell, distribute or lease your personal information to
          third parties unless we have your permission or are required by law to
          do so. We may use your personal information to send you promotional
          information about third parties which we think you may find
          interesting if you tell us that you wish this to happen.
        </p>
        <p className="font-semibold mb-5 text-sm">
          These terms and conditions form part of the Agreement between the
          Client and ourselves. Your accessing of this website or app indicates
          your understanding, agreement to and acceptance, of the full Terms and
          Conditions contained herein.
        </p>
      </div>
      <Footer />
    </PageWrapper>
  );
}

export default Privacy;
