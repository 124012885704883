import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

function ContactForm() {

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();

    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (
      !e.target.user_email.value ||
      !e.target.user_name.value ||
      !e.target.user_message.value
    ) {
      Swal.fire({
        icon: "warning",
        title: "Incomplete data",
        text: "Please, fill out all the fields",
      });
      return;
    }

    if (!e.target.user_email.value.match(validEmailRegex)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Email Address",
        text: "Please, input a valid email address",
      });
      return;
    }

    console.log(SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY);
    if (SERVICE_ID && TEMPLATE_ID && PUBLIC_KEY) {
      emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Message Sent Successfully",
          });
          e.target.reset();
          recaptchaRef.current?.reset();
        },
        (error) => {
          console.log(error.text);
          if (error.text.includes('reCAPTCHA')) {
            console.log('hello');
            Swal.fire({
              icon: "error",
              title: "Verify that you are not a robot",
              text: 'Please check the "I\'m not a robot" captcha verification checkbox',
            });
            return;
          }
          Swal.fire({
            icon: "error",
            title: "Ooops, something went wrong",
            text: error.text,
          });
        }
      );
    } else {
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: "Please try again, later",
      });
    }
  };

  return (
    <div className="md:p-8 md:mr-28">
      <form
        onSubmit={handleOnSubmit}
        className="text-[16px] flex flex-col gap-8 items-start p-6"
      >
        <div className="flex flex-col gap-1">
          <label>Email*</label>
          <input
            id="form-input-control-email"
            name="user_email"
            placeholder="Email…"
            className="bg-slate-200 border border-slate-200 rounded px-4 py-2 w-64 md:w-96"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label>Name*</label>
          <input
            id="form-input-control-last-name"
            name="user_name"
            placeholder="Name…"
            className="bg-slate-200 border border-slate-200 rounded px-4 py-2 w-64 md:w-96"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label>Message*</label>
          <textarea
            id="form-textarea-control-opinion"
            name="user_message"
            placeholder="Message…"
            className="bg-slate-200 border border-slate-200 rounded px-4 py-2 w-64 md:w-96 h-36"
          />
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY ? process.env.REACT_APP_SITE_KEY : ''}
          ref={recaptchaRef}
        />
        <button
          type="submit"
          className="border border-gray-400 rounded px-4 py-2 hover:bg-[#a2bb8b]"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
