import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-[#1d1e20] w-full flex justify-between items-center p-6">
      <div>
        <p className="text-[#c8c8c8] text-[11px] md:text-[14px]">
          © 2023 geospatialresearch.net
        </p>
      </div>
      <Link
        to="/privacy"
        className="text-[#c8c8c8] text-[11px] md:text-[14px] text-right"
      >
        EKIK Privacy Policy and Terms & Conditions
      </Link>
    </footer>
  );
}

export default Footer;
