import two from "../assets/apps/2.png";
import three from "../assets/apps/3.png";
import four from "../assets/apps/4.png";
import five from "../assets/apps/5.png";
import six from "../assets/apps/6.png";
import seven from "../assets/apps/7.png";
import eight from "../assets/apps/8.png";
import nine from "../assets/apps/9.png";
import ten from "../assets/apps/10.png";
import eleven from "../assets/apps/11.png";
import twelve from "../assets/apps/12.png";
import thirteen from "../assets/apps/13.png";
import fourteen from "../assets/apps/14.png";
import fifteen from "../assets/apps/15.png";
import sixteen from "../assets/apps/16.png";

const apps = [
  {
    name: 'Night Vision Scope /Simulator/',
    img: two,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.armynightvisionscope'
  },
  {
    name: 'Military Binoculars Stamp Cam',
    img: three,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.militarybinocularsstampcam'
  },
  {
    name: 'ARMY THERMAL CAM /SIMULATOR/',
    img: four,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.militarythermalcamera'
  },
  {
    name: 'Stamp Camera With GPS Info',
    img: five,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.gpsstampcam'
  },
  {
    name: 'Direction Compass Camera',
    img: six,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.gpscamerastamp'
  },
  {
    name: 'Cell & Net Towers World Map',
    img: seven,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.celltowers'
  },
  {
    name: 'GPS Status, Test, Data Tools',
    img: eight,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.gpsdata'
  },
  {
    name: 'Light Pollution Map –V1',
    img: nine,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.lightmap.lightpollution'
  },
  {
    name: 'Measure Map. Calculate GPS Area',
    img: ten,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.measuremap'
  },
  {
    name: 'MGRS Live Map and Mil. Compass',
    img: eleven,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.mgrs.android'
  },
  {
    name: 'Tactical Combat Land Nav',
    img: twelve,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.tacticalcombatresearchandnavigation'
  },
  {
    name: 'Offline Map Land Navigation E1',
    img: thirteen,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.offlinemaps'
  },
  {
    name: 'Land Navigation: Waypoint',
    img: fourteen,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.waypointes'
  },
  {
    name: 'Maps and Navigation All In One',
    img: fifteen,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.smarttools.nova'
  },
  {
    name: 'GPS Location info. Coordinates',
    img: sixteen,
    link: 'https://play.google.com/store/apps/details?id=com.ekik.locationinfo'
  },
]

export default apps;