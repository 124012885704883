import { useEffect, useState } from "react";
import playLogo from "../assets/play.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import logoLeft from "../assets/logi.png";
import logoMiddleTop from "../assets/star.png";
import logoMiddleBottom from "../assets/army logos.png";

function Navbar() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <nav className="w-full mt-0 bg-[#1d1e20] md:h-44 h-36 drop-shadow-2xl shadow-lg">
        <div className="w-[85%] flex items-center justify-around m-auto pt-1">
          <Link to="/" className="p-10 md:flex-1">
            <img
              src={logoLeft}
              alt="logo company"
              className="w-64 h-18 object-contain cursor-pointer"
            />
          </Link>
          <div className="hidden lg:flex-1 lg:flex lg:flex-col lg:gap-2 items-center pr-16">
            <img
              src={logoMiddleTop}
              alt="logo star"
              className="w-16 h-16 m-auto object-contain"
            />
            <img
              src={logoMiddleBottom}
              alt="army logos"
              className="w-64 h-18 object-contain"
            />
            <h2 className="text-[16px] text-[#8b8b8b]">
              THE MILITARY APPS PACKAGE
            </h2>
          </div>
          <div className="flex flex-col items-end md:flex-1 mt-6">
            <a
              className="hidden md:flex md:items-center"
              href="https://play.google.com/store/apps/dev?id=7043551059419531428"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={playLogo}
                alt="google play logo"
                className="w-28 h-28"
              />
            </a>
            <div className="hidden md:flex w-[100%] gap-8 justify-end flex-nowrap">
              {/* <Link
                to="/"
                className={
                  pathname === "/"
                    ? "text-[14px] text-[#ffffff] text-center"
                    : "text-[14px] text-[#c8c8c8] hover:text-[#ffffff] text-center"
                }
              >
                ABOUT
              </Link> */}
              <Link
                to="/"
                className={
                  pathname === "/"
                    ? "text-[14px] text-[#ffffff] text-center"
                    : "text-[14px] text-[#c8c8c8] hover:text-[#ffffff] text-center"
                }
              >
                PROJECTS
              </Link>
              <Link
                to="/privacy"
                className={
                  pathname === "/privacy"
                    ? "text-[14px] text-[#ffffff] text-center"
                    : "text-[14px] text-[#c8c8c8] hover:text-[#ffffff] text-center"
                }
              >
                PRIVACY
              </Link>
              <Link
                to="/contact"
                className={
                  pathname === "/contact"
                    ? "text-[14px] text-[#ffffff] text-center"
                    : "text-[14px] text-[#c8c8c8] hover:text-[#ffffff] text-center whitespace-nowrap"
                }
              >
                CONTACT
              </Link>
            </div>
          </div>
          <div className="block cursor-pointer md:hidden" onClick={handleNav}>
            {nav ? (
              <AiOutlineClose size={40} className="text-white" />
            ) : (
              <AiOutlineMenu size={40} className="text-white" />
            )}
          </div>
          <div
            className={
              nav
                ? "w-full bg-[#1d1e20] text-white absolute top-[130px] left-0 right-0 flex flex-col gap-4 pb-4 pt-4 justify-center text-center"
                : "absolute left-[-100%]"
            }
          >
            <div>
              <Link
                to="/"
                className="text-lg hover:font-bold w-[30%]"
                onClick={() => setNav(false)}
              >
                ABOUT
              </Link>
            </div>
            <div>
              <Link
                to="/"
                className="text-lg hover:font-bold w-[30%]"
                onClick={() => setNav(false)}
              >
                PROJECTS
              </Link>
            </div>
            <div>
              <Link
                to="/privacy"
                className="text-lg hover:font-bold w-[30%]"
                onClick={() => setNav(false)}
              >
                PRIVACY
              </Link>
            </div>
            <div>
              <Link
                to="/contact"
                className="text-lg hover:font-bold whitespace-nowrap w-[30%]"
                onClick={() => setNav(false)}
              >
                CONTACT
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <a
                className="flex items-center mt-[-12px]"
                href="https://play.google.com/store/apps/dev?id=7043551059419531428"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={playLogo}
                  alt="google play logo"
                  className="w-28 h-28"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div>
        <p className="bg-[#a2bb8b] text-center text-[11px] md:text-[14px] text-[#000000] p-[0.5]">
          The Military Apps package offers a comprehensive suite of tools
          designed to provide the most accurate and reliable intormation for
          military personnel and outdoor enthusiasts
        </p>
      </div>
    </>
  );
}

export default Navbar;
