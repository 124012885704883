import PageWrapper from "../components/PageWrapper";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import contact from "../assets/contact.png";

function Contact() {
  return (
    <PageWrapper>
      <div className="flex flex-col justify-center lg:flex-row lg:justify-around lg:items-start">
        <div className="flex flex-col gap-4 p-8 lg:w-80 lg:mt-14 lg:ml-24 text-center">
          <h2 className="text-2xl ">Get in touch with us</h2>
          <p className="lg:mt-8 mt-4">
            Please, feel free to leave us a message for any inquiries, issues or
            suggestions.
          </p>
          <div className="flex justify-center">
            <img
              src={contact}
              alt="contact icon"
              className="w-36 h-36 lg:w-64 lg:h-64 object-contain lg:mt-8"
            />
          </div>
        </div>
        <ContactForm />
      </div>
      <Footer />
    </PageWrapper>
  );
}

export default Contact;
